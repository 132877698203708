
.edit-item-modal__delete-btn {
  color: #F44336;
}

.edit-item-modal__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
