body {
  margin: 0;
  background-color: #eeeeee;
}

.form-field {
  margin: 0.5rem 0;
}

.form-field:first-child {
  margin-top: 0;
}

.form-field:last-child {
  margin-bottom: 0;
}
