.create-item-modal__radio--margin {
  margin-right: 3rem;
}

.create-item-modal__radio-group {
  display: flex;
  flex-direction: row;
}

.create-item-modal__title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex: 0 0 auto;
  margin: 0;
  padding: 16px 24px;
}

.create-item-modal__close-btn {
  padding: 0;
}
