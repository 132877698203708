
.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
  }

  &__navigate-btn {
    margin-bottom: 1rem;
  }

  &__search_create {
    display: flex;
  }

  &__items {
    margin-top: 4rem;
  }

  &__spinner {
    margin-top: 8rem;

    display: flex;
    justify-content: center;
  }

  .create-btn{
    margin-left: 3rem;
    flex-shrink: 0;
  }

  .create-btn__create-icon{
    margin-left: 0.5rem;
  }

  @media (min-width: 1080px) {
    &__content {
      margin: 4rem auto;
      width: 1024px;
    }
  }

}
