.new-release-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem;
  }

  &__navigate-btn {
    margin-bottom: 1rem;
  }

}